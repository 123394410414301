@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  scroll-behavior: smooth;
}
body {
  background: #030E14;
}

