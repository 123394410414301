@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  max-width: 100vw !important;
  overflow-x: hidden !important;
}

/* @font-face {
    font-family: 'sling';
    src: local('SlingBold');
         url('./SlingBold') format('ttf')
} */

img, p, span, h1, h2, h3, h4, h5, h6 {
  @apply select-none;
  -webkit-user-drag: none;
  user-drag: none;
}

@font-face {
    font-family: 'Sling';
    src: local('SlingBold'), url("/public/SlingBold.ttf") format('truetype');
    /* other formats include: 'woff2', 'truetype, 'opentype',
                              'embedded-opentype', and 'svg' */
  }
@font-face {
    font-family: 'Stapel';
    src: local('Stapel'), url("/public/Stapel.ttf") format('truetype');
    /* other formats include: 'woff2', 'truetype, 'opentype',
                              'embedded-opentype', and 'svg' */
  }

  .sling {
    font-family: 'Sling';
  }

  .stapel {
    font-family: 'Stapel';
  }

  .about {
    background: url("/public/images/about-bg.png");
    background-size: 100% 100%;
  }
  .AccordionTrigger {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  width: 100%;
  }
  /* styles.css */
  .AccordionContent {
    overflow: hidden;
  }
  .AccordionContent[data-state='open'] {
    animation: slideDown 300ms ease-out;
    animation-fill-mode: forwards
  }
  .AccordionContent[data-state='closed'] {
    animation: slideUp 300ms ease-in-out;
    animation-fill-mode: backwards
  }
  
  @keyframes slideDown {
    from {
      height: 0;
    }
    to {
      height: var(--radix-accordion-content-height);
    }
  }
  
  @keyframes slideUp {
    from {
      height: var(--radix-accordion-content-height);
    }
    to {
      height: 14px;
    }
  }
  
  @media (min-width: 1500px) {
    .AccordionContent[data-state='open'] {
      animation: slideDown 300ms ease-out;
      animation-fill-mode: forwards;
      height: 90px;
    }
    .AccordionContent[data-state='closed'] {
      animation: slideUp 300ms ease-in;
      animation-fill-mode: backwards;
      height: 0;
    }
    
    @keyframes slideDown {
      from {
        height: 0;
      }
      to {
        height: 80px;
      }
    }
    
    @keyframes slideUp {
      from {
        height: 80px;
      }
      to {
        height: 0
      }
    }
  }
  


  .drop-bg {
    background-image: url('/public/images/faq-bg.png');
    background-size: 100% 100%;
    padding: .5em;
    width: 32px;
    height: 32px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  /* styles.css */
.AccordionChevron {
    transition: transform 300ms;
  }
  .AccordionTrigger[data-state='open'] > .drop-bg {
    transform: rotate(180deg);
  }
  

  .AccordionTrigger {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  width: 100%;
  }
  /* .AccordionTrigger[data-state='open'] {
    border: 1px solid #00D3EF;
  } */

  .AccordionRoot > .AccordionItem[data-state='open'] {
    border: 1px solid rgba(0,211,239,.5);
  }
  /* styles.css */
  .AccordionContent {
    overflow: hidden;
  }


  .AccordionContent[data-state='open'] {
    animation: slideDown 300ms ease-out;
    animation-fill-mode: forwards;
  }
  .AccordionContent[data-state='closed'] {
    animation: slideUp 300ms ease-in-out;
    animation-fill-mode: backwards;
  }
  
  @keyframes slideDown {
    from {
      height: 0;
    }
    to {
      height: var(--radix-accordion-content-height);
    }
  }
  
  @keyframes slideUp {
    from {
      height: var(--radix-accordion-content-height);
    }
    to {
      height: 14px;
    }
  }
  
  @media (min-width: 1500px) {
    .AccordionContent[data-state='open'] {
      animation: slideDown 300ms ease-out;
      animation-fill-mode: forwards;
    }
    .AccordionContent[data-state='closed'] {
      animation: slideUp 300ms ease-in;
      animation-fill-mode: backwards;
      height: 0;
    }
    
    @keyframes slideDown {
      from {
        height: 0;
      }
      to {
        height: 80px;
      }
    }
    
    @keyframes slideUp {
      from {
        height: 80px;
      }
      to {
        height: 0
      }
    }
  }
  